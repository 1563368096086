import { OidcAccessToken } from "@axa-fr/react-oidc/dist/ReactOidc";

/**
 * Handles submitting feedback for a specific message.
 *
 * @param {string} messageID - The ID of the message for which feedback is being submitted.
 * @param {boolean} feedback - The feedback value (true for positive, false for negative).
 * @param {string} details - Additional details or comments for the feedback.
 * @param {OidcAccessToken} accessToken - The access token for authorization.
 *
 * @return {Promise<Response>} A Promise that resolves with the response from the feedback submission endpoint.
 */
export async function handleFeedback(
  messageID: string,
  feedback: boolean,
  details: string,
  accessToken: OidcAccessToken,
): Promise<Response> {
  const host: string = process.env.REACT_APP_LLMGATEWAY_HOST ?? "";
  const feedbackData: FeedbackModel = {
    message_id: messageID,
    feedback: feedback,
    details: details,
  };
  const headers: HeadersInit = {
    Authorization: `Bearer ${accessToken.accessToken}`,
    "Content-Type": "application/json",
  };

  return await fetch(`${host}/feedback/submit`, {
    method: "POST",
    body: JSON.stringify(feedbackData),
    headers: headers,
  });
}

interface FeedbackModel {
  message_id: string;
  feedback: boolean;
  details?: string;
}
