import cleanGatewayResponse from "./cleanGatewayResponse";

/**
 * Reads chunks from a ReadableStream and processes them using a callback function.
 *
 * @param {ReadableStream} stream - The ReadableStream to read data from.
 * @param {(token: string) => void} onNewChunk - Callback function that receives each chunk as it arrives.
 *
 * @return {Promise<string>} - A string that concatenates all the chunks read from the stream.
 */
export default async function readMessageStream(
  stream: ReadableStream,
  onNewChunk: (token: string) => void = () => {}, // callback function gets chunks as they arrive
): Promise<string> {
  const reader = stream.getReader();
  const chunks: string[] = [];

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;
    if (value) {
      const chunk = cleanGatewayResponse(value);
      chunks.push(chunk);
      onNewChunk(chunk);
    }
  }

  return chunks.join("");
}
