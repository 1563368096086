import { DropDownChunk } from "../../../types/classes/DropDownChunk";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useCallback, useEffect } from "react";
import snakeToTitleCase from "../../../functions/formatting/snakeToTitleCase";

export const DropDownChunkDisplay = ({
  chunk,
  id,
  value,
  onUpdate,
}: {
  chunk: DropDownChunk;
  id: number;
  value: string;
  onUpdate: (chunkId: number, newValue: string) => void;
}) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      onUpdate(id, event.target.value);
    },
    [chunk, id, value, onUpdate],
  );

  useEffect(() => {
    onUpdate(id, chunk.options[0]);
  }, [chunk]);

  return (
    <Box sx={{ display: "block", width: "100%" }}>
      <FormControl size="small" className="drop-down-chunk" fullWidth>
        <InputLabel id={id + "-" + "label"}>
          {snakeToTitleCase(chunk.name)}
        </InputLabel>
        <Select
          onChange={handleChange}
          fullWidth
          defaultValue={chunk.options[0]}
          label={snakeToTitleCase(chunk.name)}
          labelId={id + "-" + "label"}
        >
          {chunk.options.map((o) => (
            <MenuItem value={o} key={o}>
              {o}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
