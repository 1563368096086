import { useContext } from "react";
import { OidcConfigurationNameContext } from "../contexts/OidcConfigurationNameContext";

export const useOidcConfigurationName = () => {
  const context = useContext(OidcConfigurationNameContext);
  if (context === undefined) {
    throw new Error(
      "useOidcConfigurationName must be used within an OidcConfigurationNameProvider",
    );
  }
  return context;
};
