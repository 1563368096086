import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/material/";
import { FormFactor } from "../../types/enums/FormFactor";
import { useFormFactor } from "../../hooks/useFormFactor";

export const TemplatePreviewModal = ({
  newText,
  open,
  onTemplateSaved,
  onPreviewClosed,
}: {
  newText: string;
  open: boolean;
  onTemplateSaved: (newText: string) => void;
  onPreviewClosed: () => void;
}) => {
  const formFactor = useFormFactor();
  return (
    <Dialog
      open={open}
      onClose={onPreviewClosed}
      fullWidth={true}
      maxWidth="md"
      fullScreen={formFactor < FormFactor.Tablet}
    >
      <DialogTitle>Template Preview</DialogTitle>
      <DialogContent>
        <Typography>{newText}</Typography>
        <br />
        <Stack direction="row">
          <Button onClick={onPreviewClosed} color="error">
            Close
          </Button>
          <Button
            onClick={() => {
              onTemplateSaved(newText);
              onPreviewClosed();
            }}
          >
            Insert
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
