import ILoadableContext from "../types/interfaces/ILoadableContext";
import IRAGSource from "../types/interfaces/IRAGSource";
import { createContext } from "react";

export interface IRAGSourcesContext extends ILoadableContext {
  ragSources: IRAGSource[];
}

export const RAGSourcesContext = createContext<IRAGSourcesContext>({
  ragSources: [],
  loading: true,
  error: null,
  reload: () => {},
  name: "rag sources",
});
