/**
 * Cleans the gateway response data, extracting relevant information from a Uint8Array representation.
 *
 * @param {Uint8Array | undefined} data - The Uint8Array representing the gateway response data, or undefined if no data is provided.
 *
 * @return {string} The cleaned text extracted from the response data, or an empty string if no data is provided or if an error occurs during processing.
 */
export default function cleanGatewayResponse(
  data: Uint8Array | undefined,
): string {
  let decoded = new TextDecoder().decode(data);
  let text = "";
  // attempt to parse image data, if this fails it's not an image
  try {
    const image = JSON.parse(decoded);
    if (image?.data !== undefined && image.data[0] !== undefined) {
      return `![${image.data[0].revised_prompt}](${image.data[0].url})`;
    }
  } catch (error) {}
  for (let l of decoded.split("\n")) {
    try {
      l = l.trim();
      l = l.replace("data: ", "");
      if (l === "") continue;
      const parsed = JSON.parse(l);
      let content = parsed.choices[0].delta.content;
      text = text.concat(content);
    } catch (error) {
      console.error(
        `There was an error cleaning the streamed data: ${error}. Raw data: ${l}`,
      );
      return text;
    }
  }
  return text;
}
