import { OidcAccessToken } from "@axa-fr/react-oidc/dist/ReactOidc";
import IRAGSource from "../../types/interfaces/IRAGSource";

export async function getRAGSources(
  accessToken: OidcAccessToken,
): Promise<IRAGSource[]> {
  const host: string = process.env.REACT_APP_LLMGATEWAY_HOST ?? "";
  const headers: HeadersInit = {
    Authorization: `Bearer ${accessToken.accessToken}`,
    "Content-Type": "application/json",
  };

  let resp = await fetch(`${host}/chat-config/rag_sources`, {
    method: "GET",
    headers: headers,
  });
  return (await resp.json()).rag_sources;
}
