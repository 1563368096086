import hast from "hast";

/**
 * Retrieves nested class names from the children elements of the given HTML element.
 *
 * @param {Array<string | number | boolean>} carry - An array to accumulate the class names.
 * @param {hast.Element} current - The current element to extract class names from.
 *
 * @return {Array<string | number | boolean>} - An array containing the accumulated class names
 * from the children elements of the provided element.
 */
export default function getNestedClassNamesFromElementChildren(
  carry: (string | number | boolean)[],
  current: hast.Element,
): Array<string | number | boolean> {
  if (current.properties?.className == undefined) {
    return carry;
  }
  return [
    ...carry,
    ...(Array.isArray(current.properties.className)
      ? current.properties.className
      : [current.properties.className]),
  ];
}
