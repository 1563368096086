import { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { OidcConfigurationNameContext } from "../../contexts/OidcConfigurationNameContext";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import IPromptTemplate from "../../types/interfaces/IPromptTemplate";
import { PromptTemplatesContext } from "../../contexts/PromptTemplatesContext";
import { getPromptTemplates } from "../../functions/requests/getPromptTemplates";

export const PromptTemplatesProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { oidcConfigurationName } = useContext(OidcConfigurationNameContext);
  const oidcAccessToken = useOidcAccessToken(oidcConfigurationName);
  const [promptTemplates, setPromptTemplates] = useState<IPromptTemplate[]>([]);
  const [templateRoles, setTemplateRoles] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const reloadOptions = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const promptTemplatesResponse = await getPromptTemplates(oidcAccessToken);
      setPromptTemplates(promptTemplatesResponse);
      setTemplateRoles(
        promptTemplatesResponse
          .reduce(
            (accumulated, template) => accumulated.concat(template.role),
            [] as string[],
          )
          .reduce(
            (accumulated, r) =>
              accumulated.some((x) => x == r) //|| r === "*"
                ? accumulated
                : [r, ...accumulated],
            [] as string[],
          ),
      );
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    void reloadOptions();
  }, [reloadOptions]);

  return (
    <PromptTemplatesContext.Provider
      value={{
        promptTemplates: promptTemplates,
        templateRoles: templateRoles,
        useTemplates: promptTemplates.length > 0,
        ignoreErrors: true,
        loading: loading,
        error: error,
        reload: reloadOptions,
        name: "prompt templates",
      }}
    >
      {children}
    </PromptTemplatesContext.Provider>
  );
};
