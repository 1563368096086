import IChatMessage from "../../types/interfaces/IChatMessage";
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { MessageRole } from "../../types/enums/MessageRole";
import ChatMessage from "./ChatMessage";
import IncomingMessage, { IncomingMessageStream } from "./IncomingMessage";
import AssistantMessageActionBar from "../AssistantMessageActionBar";
import copy from "copy-to-clipboard";
import ReactMarkdown from "react-markdown";
import downloadText from "../../functions/chat-tools/downloadText";
import IModel from "../../types/interfaces/IModel";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import React, { useEffect, useRef, useState } from "react";
import FeedbackModal from "../FeedbackModal";
import { db } from "../../database/db";
import { useSending } from "../../hooks/useSending";
import { useOidcConfigurationName } from "../../hooks/useOidcConfigurationName";
import { handleFeedback } from "../../functions/requests/handleFeedback";

export type ChatHistoryProps = {
  chatMessages: IChatMessage[];
  stream?: IncomingMessageStream;
  onRegenerateMessage: () => void;
  heightOffset: number;
  model: IModel | null;
  defaultSystemPrompt: string;
};

export default function ChatHistory({
  chatMessages,
  stream,
  onRegenerateMessage,
  heightOffset,
  model,
  defaultSystemPrompt,
}: ChatHistoryProps) {
  const { oidcConfigurationName } = useOidcConfigurationName();
  const oidcAccessToken = useOidcAccessToken(oidcConfigurationName);
  const { isSending } = useSending();

  const [feedback, setFeedBack] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      document.documentElement.scrollTop === 0 ||
      document.documentElement.scrollHeight -
        (document.documentElement.scrollTop +
          document.documentElement.clientHeight) <
        200
    ) {
      containerRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [chatMessages]);

  if (chatMessages.length === 0) {
    return (
      <Alert severity="info" sx={{ m: "auto" }}>
        Send a message to begin the conversation.
      </Alert>
    );
  }

  const handleCopyMessage = () => {
    copy(chatMessages[chatMessages.length - 1].content);
  };

  let systemMessage = <div />;
  if (chatMessages.length > 0 && chatMessages[0].role == MessageRole.System) {
    systemMessage = (
      <Box
        display="flex"
        flexDirection="column"
        justifyItems="start"
        width="100%"
        maxWidth="min(960px, 100%)"
      >
        <details>
          <Typography
            variant="overline"
            component="summary"
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            System Prompt{" "}
            {chatMessages[0].content === defaultSystemPrompt ? (
              <></>
            ) : (
              <span>(customized)</span>
            )}
          </Typography>
          <ReactMarkdown className="markdown">
            {chatMessages[0].content}
          </ReactMarkdown>
          <Divider />
        </details>
      </Box>
    );
  }

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const submitFeedback = (details: string) => {
    const lastMessage = chatMessages[chatMessages.length - 1];
    lastMessage.hasFeedback = true;
    db.chatMessage.put(lastMessage, lastMessage.id);
    handleFeedback(lastMessage.id, feedback, details, oidcAccessToken);
  };

  const handleDownloadMessage = () => {
    const lastMessage = chatMessages[chatMessages.length - 1];
    downloadText("exportedChatMessage.txt", lastMessage.content);
  };

  const handleGoodMessage = () => {
    setFeedBack(true);
    openModal();
  };

  const handleBadMessage = () => {
    setFeedBack(false);
    openModal();
  };

  const scrollToBottom = () => {
    if (containerRef.current) {
      if (
        document.documentElement.scrollHeight -
          (document.documentElement.scrollTop +
            document.documentElement.clientHeight) <
        200
      ) {
        containerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    }
  };

  let incomingMessageElement = <div />;
  if (isSending) {
    incomingMessageElement = (
      <IncomingMessage
        stream={stream}
        scrollCallback={scrollToBottom}
        model={model}
      ></IncomingMessage>
    );
  } else if (
    chatMessages[chatMessages.length - 1].role === MessageRole.Assistant
  ) {
    incomingMessageElement = (
      <AssistantMessageActionBar
        onRegenerateMessage={onRegenerateMessage}
        onCopyMessage={handleCopyMessage}
        onMessageDownload={handleDownloadMessage}
        onGoodFeedback={handleGoodMessage}
        onBadFeedback={handleBadMessage}
        disableFeedback={chatMessages[chatMessages.length - 1].hasFeedback}
      />
    );
  }
  return (
    <Stack
      spacing={{ xs: 1, sm: 2 }}
      sx={{
        padding: "1em",
        boxSizing: "border-box",
        marginLeft: "auto",
        marginRight: "auto",
        minHeight: `calc(100vh - ${heightOffset}px)`,
        scrollMarginBottom: "120px",
      }}
      direction="column"
      useFlexGap
      flexWrap="wrap"
      alignItems="center"
      maxWidth={960}
      width="100%"
      ref={containerRef}
    >
      {systemMessage}
      {chatMessages
        .filter((m) => m.role !== MessageRole.System)
        .map((m, index, a) => (
          <Box key={index} sx={{ padding: 0, width: "100%" }}>
            <ChatMessage message={m} fromUser={m.role === MessageRole.User} />
            {index === a.length - 1 ? (
              <></>
            ) : (
              <Divider sx={{ width: "100%" }} />
            )}
          </Box>
        ))}
      {incomingMessageElement}
      <FeedbackModal
        isVisible={isModalVisible}
        onClose={closeModal}
        onSubmit={submitFeedback}
      />
      <div style={{ height: `${heightOffset * 3}px` }}>
        {isSending ? <CircularProgress /> : <></>}
      </div>
    </Stack>
  );
}
