import { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { OidcConfigurationNameContext } from "../../contexts/OidcConfigurationNameContext";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import IChatOptions from "../../types/interfaces/IChatOptions";
import { ChatOptionsContext } from "../../contexts/ChatOptionsContext";
import { getChatOptions } from "../../functions/requests/getChatOptions";

export const ChatOptionsProvider = ({ children }: { children: ReactNode }) => {
  const { oidcConfigurationName } = useContext(OidcConfigurationNameContext);
  const oidcAccessToken = useOidcAccessToken(oidcConfigurationName);
  const [chatOptions, setChatOptions] = useState<IChatOptions>({
    default_system_prompt: "",
    help_text: "",
    show_rag: false,
    group: [],
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const reloadOptions = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const chatOptionsResponse = await getChatOptions(oidcAccessToken);
      setChatOptions(chatOptionsResponse);
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    void reloadOptions();
  }, [reloadOptions]);

  return (
    <ChatOptionsContext.Provider
      value={{
        chatOptions: chatOptions,
        loading: loading,
        error: error,
        reload: reloadOptions,
        name: "chat options",
      }}
    >
      {children}
    </ChatOptionsContext.Provider>
  );
};
