import { ChatOptionsContext } from "../contexts/ChatOptionsContext";
import { useContext } from "react";

export const useChatOptions = () => {
  const context = useContext(ChatOptionsContext);
  if (context === undefined) {
    throw new Error("useChatOptions must be used within a ChatOptionsProvider");
  }
  return context;
};
