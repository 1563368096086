import ILoadableContext from "../types/interfaces/ILoadableContext";
import IPromptTemplate from "../types/interfaces/IPromptTemplate";
import { createContext } from "react";

export interface IPromptTemplatesContext extends ILoadableContext {
  promptTemplates: IPromptTemplate[];
  templateRoles: string[];
  useTemplates: boolean;
}

export const PromptTemplatesContext = createContext<IPromptTemplatesContext>({
  promptTemplates: [],
  templateRoles: [],
  useTemplates: false,
  loading: true,
  error: null,
  reload: () => {},
  name: "prompt templates",
});
