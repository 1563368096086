import IChatMessage from "../../types/interfaces/IChatMessage";
import IModel from "../../types/interfaces/IModel";
import IGatewayAPIRequest from "../../types/interfaces/IGatewayAPIRequest";
import convertGatewayRequest from "./convertGatewayRequest";
import { OidcAccessToken } from "@axa-fr/react-oidc/dist/ReactOidc";
import trimConversationHistory from "../comparisons-arrays/trimConversationHistory";
import cleanMessages from "../comparisons-arrays/cleanMessages";

interface FetchPromiseWithAbort extends Promise<Response> {
  abort: AbortController;
}

/**
 * Sends a chat completion request to LLMGateway
 *
 * @param {IChatMessage[]} chatHistory - Array of chat messages.
 * @param {IModel} model - The model to be used for processing the chat messages.
 * @param {OidcAccessToken} accessToken - Access token for authorization.
 * @param {boolean} [rag=false] - Flag indicating whether to use RAG for response generation.
 * @param {boolean} [pii=false] - Flag indicating whether to filter PII
 * @param {boolean} [showRag=false] - Flag indicating whether RAG is shown on the current configuration
 * @param {Function} [popupCallback=() => {}] - Callback function for displaying popups.
 *
 * @return {FetchPromiseWithAbort} - A promise that resolves with the API response data.
 */
export default function sendGatewayRequest(
  chatHistory: IChatMessage[],
  model: IModel,
  accessToken: OidcAccessToken,
  rag: string[],
  pii: boolean = false,
  showRag: boolean = false,
  popupCallback: (s: string) => void = () => {},
): FetchPromiseWithAbort {
  const host: string = process.env.REACT_APP_LLMGATEWAY_HOST ?? "";
  const abortController = new AbortController();
  const trimmedHistory = trimConversationHistory(
    chatHistory,
    model,
    popupCallback,
  );
  const gateway_api_request: IGatewayAPIRequest = {
    messages: cleanMessages(trimmedHistory),
    model: model.internal_name,
    rag: showRag ? rag : [],
    stream: true,
  };
  const headers: HeadersInit = {
    Authorization: `Bearer ${accessToken.accessToken}`,
    "Content-Type": "application/json",
  };

  const { signal } = abortController;

  const fetchPromise = fetch(`${host}/chat/completions`, {
    method: "POST",
    body: JSON.stringify(convertGatewayRequest(gateway_api_request)),
    headers: headers,
    signal, // Pass signal option
  }) as FetchPromiseWithAbort; // Cast to the extended type

  fetchPromise.abort = abortController;
  if (trimmedHistory.length < 2) {
    fetchPromise.abort.abort(
      "Something went wrong: The message exceeded the context window. Please send a shorter message or consider selecting a model with a larger context window.",
    );
  }
  return fetchPromise;
}
