import {
  Divider,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import IConversationInfo from "../types/interfaces/IConversationInfo";
import { ListItemButton } from "@mui/material/";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { Fragment } from "react";
import {
  CalendarMonth,
  Delete,
  Edit,
  MoreVertRounded,
} from "@mui/icons-material";
import { chainEventHandlers } from "material-ui-popup-state/chainEventHandlers";
import formatDateOrDateString from "../functions/formatting/formatDateOrDateString";
import { useSending } from "../hooks/useSending";
import { useFormFactor } from "../hooks/useFormFactor";
import { FormFactor } from "../types/enums/FormFactor";
import { useSidebarOpen } from "../hooks/useSidebarOpen";

export type ConversationListItemProps = {
  conversation: IConversationInfo;
  onConversationInfoChanged: (
    id: string,
    name: string,
    updated: Date,
    named: boolean,
  ) => void;
  onConversationDeleted: (id: string) => void;
  onConversationClicked: (id: string) => void;
  active: boolean;
};

export default function ConversationListItem({
  conversation,
  onConversationInfoChanged,
  onConversationDeleted,
  onConversationClicked,
  active,
}: ConversationListItemProps) {
  const { isSending } = useSending();
  const formFactor = useFormFactor();
  const { closeSidebar } = useSidebarOpen();

  function handleItemClick(e: React.MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
  }

  function handleRename(e: React.MouseEvent, p: any) {
    handleItemClick(e);
    let newName = prompt("Enter a new name for the conversation");
    let cleaned = newName ?? "";
    cleaned = cleaned.trim() === "" ? conversation.name : cleaned;
    onConversationInfoChanged(
      conversation.id,
      cleaned,
      conversation.updated,
      true,
    );
    p.close();
  }

  function handleDelete(e: React.MouseEvent, p: any) {
    handleItemClick(e);
    onConversationDeleted(conversation.id);
    p.close();
  }

  return (
    <Tooltip
      title={`${conversation.name}`}
      placement={formFactor > FormFactor.Mobile ? "right" : "top"}
      arrow
    >
      <ListItemButton
        sx={{ display: "flex", justifyContent: "space-between" }}
        onClick={() => {
          onConversationClicked(conversation.id);
          if (formFactor == FormFactor.Mobile) {
            closeSidebar();
          }
        }}
        selected={active}
        disabled={isSending}
      >
        <Typography noWrap>{conversation.name}</Typography>
        <PopupState
          popupId={"rename-" + conversation.name}
          variant={"popover"}
          disableAutoFocus={false}
          parentPopupState={null}
        >
          {(popupState) => (
            <Fragment>
              <IconButton
                {...chainEventHandlers(bindTrigger(popupState), {
                  onClick: (e: React.MouseEvent) => {
                    handleItemClick(e);
                    bindTrigger(popupState).onClick(e);
                  },
                })}
              >
                <MoreVertRounded />
              </IconButton>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={(e) => handleRename(e, popupState)}>
                  <Edit />
                  &nbsp; Rename
                </MenuItem>
                <MenuItem onClick={(e) => handleDelete(e, popupState)}>
                  <Delete />
                  &nbsp; Delete
                </MenuItem>
                <Divider />
                <ListItem>
                  <CalendarMonth />
                  &nbsp;
                  <i>{formatDateOrDateString(conversation.updated, true)}</i>
                </ListItem>
              </Menu>
            </Fragment>
          )}
        </PopupState>
      </ListItemButton>
    </Tooltip>
  );
}
