import ILargeLanguageModel from "../../types/interfaces/ILargeLanguageModel";

/**
 * Retrieves the default model from a list of large language models.
 *
 * @param {ILargeLanguageModel[]} llms - An array containing multiple large language models.
 * @return {ILargeLanguageModel} - The default large language model found in the list. If no default model is found, the first model in the list is returned.
 */
export default function getDefaultModelFromList(
  llms: ILargeLanguageModel[],
): ILargeLanguageModel {
  if (llms.length === 0) {
    throw new Error(
      "There are no available LLMs. This function should never be called from a context where LLMs are not yet loaded.",
    );
  }
  return llms.find((llm) => llm.default) ?? llms[0];
}
