import { useContext } from "react";
import { AvailablePromotionsCarouselContext } from "../contexts/AvailablePromotionsCarouselContext";

export const usePromotionsCarousel = () => {
  const context = useContext(AvailablePromotionsCarouselContext);
  if (context === undefined) {
    throw new Error(
      "usePromotionsCarousel must be used within an AvailablePromotionsCarouselProvider",
    );
  }
  return context;
};
