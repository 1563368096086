import ILargeLanguageModel from "../../types/interfaces/ILargeLanguageModel";

/**
 * Retrieves an array of large language models from the server.
 * This method makes an asynchronous call to fetch all available models from the server.
 * Throws an error if the list of models is empty.
 *
 * @return Promise<Array<ILargeLanguageModel>> An array of large language models available on the server.
 */
export async function getAllModels(): Promise<Array<ILargeLanguageModel>> {
  const host: string = process.env.REACT_APP_LLMGATEWAY_HOST ?? "";
  const headers: HeadersInit = {
    "Content-Type": "application/json",
  };
  let resp = await fetch(`${host}/models/all`, {
    method: "GET",
    headers: headers,
    signal: AbortSignal.timeout(5000),
  });
  const models = (await resp.json()).models;
  if (models.length === 0) {
    throw new Error(
      "The list of AI models was empty. Please check that the available AI models are correctly configured in Elasticsearch.",
    );
  }
  return models;
}
