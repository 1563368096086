import { createContext } from "react";

export const SidebarOpenContext = createContext<{
  sidebarOpen: boolean;
  sidebarWidth: number;
  openSidebar: () => void;
  closeSidebar: () => void;
  toggleSidebar: () => void;
}>({
  sidebarOpen: false,
  sidebarWidth: 0,
  openSidebar: () => {},
  closeSidebar: () => {},
  toggleSidebar: () => {},
});
