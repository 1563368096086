/**
 * Formats a Date object or a string representing a date into a specific date/time string format.
 *
 * @param {Date | string} d - The Date object or date string to be formatted.
 * @param {boolean} fancy - Boolean flag to determine if the formatted date string should include time details.
 *
 * @return {string} A formatted date string based on the input date and the 'fancy' flag. Returns the formatted date string with or without time details depending on the value of 'fancy'.
 */
export default function formatDateOrDateString(
  d: Date | string,
  fancy: boolean = true,
): string {
  if (fancy) {
    const date = d instanceof Date ? d : new Date(d);
    return date.toLocaleString("en-us", {
      timeZone: "America/New_York",
      year: "2-digit",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }
  return d instanceof Date
    ? d.toLocaleString("en-us")
    : new Date(d).toLocaleDateString("en-us");
}
