import { ReactNode } from "react";
import { useChatOptions } from "../hooks/useChatOptions";
import { usePromptTemplates } from "../hooks/usePromptTemplates";
import { Loading } from "../Loading";

export const AuthenticatedLoadingWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  const chatOptionsContext = useChatOptions();
  const promptTemplatesContext = usePromptTemplates();
  return (
    <Loading contexts={[chatOptionsContext, promptTemplatesContext]}>
      {children}
    </Loading>
  );
};
