import { ReactNode, useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { FormFactorContext } from "../../contexts/FormFactorContext";
import { FormFactor } from "../../types/enums/FormFactor";

export const FormFactorProvider = ({ children }: { children: ReactNode }) => {
  const mobile = useMediaQuery("(max-width:480px)");
  const tablet = useMediaQuery("(min-width:480px) and (max-width:899px)");
  const desktop = useMediaQuery("(min-width:1280px)");
  let [formFactor, setFormFactor] = useState<FormFactor>(FormFactor.Desktop);
  useEffect(() => {
    if (mobile) {
      setFormFactor(FormFactor.Mobile);
    } else if (tablet) {
      setFormFactor(FormFactor.Tablet);
    } else if (desktop) {
      setFormFactor(FormFactor.Desktop);
    } else {
      setFormFactor(FormFactor.Laptop);
    }
  }, [mobile, desktop, tablet]);
  return (
    <FormFactorContext.Provider value={formFactor}>
      {children}
    </FormFactorContext.Provider>
  );
};
