import { ReactNode } from "react";
import { useChatOptions } from "../hooks/useChatOptions";
import { usePromptTemplates } from "../hooks/usePromptTemplates";
import { Loading } from "../Loading";
import { usePromotionsCarousel } from "../hooks/usePromotionsCarousel";

export const PromotionsLoadingWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { chatOptions } = useChatOptions();
  const promotionsCarouselContext = usePromotionsCarousel();
  if (chatOptions.use_promotions) {
    return <Loading contexts={[promotionsCarouselContext]}>{children}</Loading>;
  }
  return <>{children}</>;
};
