import useLocalStorage from "../hooks/useLocalStorage";
import IChatMessage from "../types/interfaces/IChatMessage";
import { Alert, Box, IconButton, Snackbar, Toolbar } from "@mui/material";
import ComposeArea, { ComposeAreaProps } from "./chat/ComposeArea";
import ChatHistory, { ChatHistoryProps } from "./chat/ChatHistory";
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import sendGatewayRequest from "../functions/requests/sendGatewayRequest";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import readMessageStream from "../functions/requests/readMessageStream";
import { MessageRole } from "../types/enums/MessageRole";
import ConversationSettings from "./ConversationSettings";
import IConversationSettings from "../types/interfaces/IConversationSettings";
import { Close } from "@mui/icons-material";
import { useLiveQuery } from "dexie-react-hooks";
import { v4 as uuid4 } from "uuid";
import { db } from "../database/db";
import sendImageGatewayRequest from "../functions/requests/sendImageGatewayRequest";
import { withTransaction } from "@elastic/apm-rum-react";
import { apm } from "@elastic/apm-rum";
import getDefaultModelFromList from "../functions/comparisons-arrays/getDefaultModelFromList";
import { OidcConfigurationNameContext } from "../contexts/OidcConfigurationNameContext";
import { DarkModeContext } from "../contexts/DarkModeContext";
import { useSending } from "../hooks/useSending";
import { useAvailableModels } from "../hooks/useAvailableModels";
import { useSidebarOpen } from "../hooks/useSidebarOpen";
import { useFormFactor } from "../hooks/useFormFactor";
import { FormFactor } from "../types/enums/FormFactor";
import { useChatOptions } from "../hooks/useChatOptions";
import { nameConversation } from "../functions/requests/nameConversation";

export default withTransaction(
  "ActiveConversationWindow",
  "component",
)(ActiveConversationWindow);

export type ActiveConversationWindowProps = {
  conversationId: string | null | undefined;
  onTranscriptDownloaded: (history: IChatMessage[]) => void;
  defaultSystemPrompt: string;
  useRagByDefault: boolean;
};

function ActiveConversationWindow({
  conversationId,
  onTranscriptDownloaded,
  defaultSystemPrompt,
  useRagByDefault,
}: ActiveConversationWindowProps) {
  const chatMessages = useLiveQuery(
    () =>
      db.chatMessage
        .where("conversationId")
        .equals(conversationId ?? "")
        .sortBy("updated"),
    [conversationId],
  );
  const { availableModels } = useAvailableModels();
  const { isSending, setSending, clearSending } = useSending();
  const { sidebarOpen, sidebarWidth } = useSidebarOpen();
  const formFactor = useFormFactor();

  const [conversationSettings, setConversationSettings] =
    useLocalStorage<IConversationSettings>("chatSettings-" + conversationId, {
      model: getDefaultModelFromList(availableModels),
      systemPrompt: defaultSystemPrompt,
      rag: [],
      pii: false,
    });
  const [stream, setStream] = useState<ChatHistoryProps["stream"]>();
  const [conversationSettingsOpen, setConversationSettingsOpen] =
    useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarText, setSnackbarText] = useState<string>("No notification");
  const { darkModeEnabled } = useContext(DarkModeContext);
  const { oidcConfigurationName } = useContext(OidcConfigurationNameContext);
  const abortControllerRef = useRef<AbortController | null>(null);
  const abort = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  }, []);
  const streamedContentRef = useRef<string>("");
  const accessToken = useOidcAccessToken(oidcConfigurationName);
  const { chatOptions } = useChatOptions();

  const updateSystemPrompt = useCallback(
    (newPrompt: string) => {
      (async function () {
        if (conversationId === null || conversationId === undefined) {
          return;
        }
        try {
          const systemMessage = await db.chatMessage.get({
            role: MessageRole.System,
            conversationId: conversationId,
          });
          if (systemMessage === undefined) {
            console.error(
              `No such message: System message for ${conversationId}`,
            );
            return;
          }
          systemMessage.content = newPrompt;
          await db.chatMessage.put(systemMessage, systemMessage.id);
        } catch (error) {
          console.log(error);
        }
      })();
    },
    [conversationId],
  );

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [conversationId]);

  useEffect(() => {
    updateSystemPrompt(conversationSettings.systemPrompt);
  }, [conversationSettings.systemPrompt, updateSystemPrompt]);

  const updateStreamedMessageText = (chunk: string) => {
    streamedContentRef.current = streamedContentRef.current += chunk;
  };

  const handleRegenerateMessage = async () => {
    let tr = apm.startTransaction("regenerate", "llm.message");
    let sp = tr?.startSpan("message");
    streamedContentRef.current = "";
    const lastAssistantMessageQuery = await db.chatMessage.filter(
      (m) =>
        m.conversationId === conversationId && m.role === MessageRole.Assistant,
    );
    const lastAssistantMessage = (
      await lastAssistantMessageQuery.sortBy("updated")
    ).pop();
    if (lastAssistantMessage === undefined) {
      return;
    }
    await db.chatMessage.delete(lastAssistantMessage.id);
    const selectedModel =
      typeof conversationSettings.model !== "string"
        ? conversationSettings.model
        : availableModels.find((llm) =>
            // @ts-ignore
            llm.aliases.includes(conversationSettings.model),
          ) ?? getDefaultModelFromList(availableModels);
    const assistantMessagePromise = !selectedModel.image_model
      ? sendGatewayRequest(
          (chatMessages ?? []).slice(0, -1),
          selectedModel,
          accessToken,
          conversationSettings.rag,
          conversationSettings.pii,
          chatOptions.show_rag,
          handleSnackbarOpen,
        )
      : sendImageGatewayRequest(
          (chatMessages ?? []).slice(0, -1),
          selectedModel,
          accessToken,
          conversationSettings.rag,
          conversationSettings.pii,
          handleSnackbarOpen,
        );
    abortControllerRef.current = assistantMessagePromise.abort;
    const newId = uuid4();
    const assistantMessage = assistantMessagePromise
      .then((response) => {
        if (response.body === null) {
          throw new Error("Couldn't get message from gateway");
        } else if (!response.ok) {
          return response.json();
        }
        const [stream1, stream2] = response.body.tee();
        setStream(stream1);
        return readMessageStream(stream2, updateStreamedMessageText);
      })
      .then((text): IChatMessage => {
        if (typeof text !== "string") {
          return {
            id: newId,
            conversationId: conversationId ?? "",
            role: MessageRole.Assistant,
            content: "Something went wrong: " + text.detail!,
            updated: new Date(),
            model:
              conversationSettings.model ??
              getDefaultModelFromList(availableModels),
            hasFeedback: false,
          };
        }
        return {
          role: MessageRole.Assistant,
          content: text,
          id: newId,
          conversationId: conversationId ?? "",
          updated: new Date(),
          model:
            conversationSettings.model ??
            getDefaultModelFromList(availableModels),
          hasFeedback: false,
        };
      })
      .catch((err) => {
        console.log(err);
        return {
          id: newId,
          conversationId: conversationId ?? "",
          role: MessageRole.Assistant,
          content: err.toString(),
          updated: new Date(),
          model:
            conversationSettings.model ??
            getDefaultModelFromList(availableModels),
          hasFeedback: false,
        };
      });

    setSending();
    assistantMessage.then((assistantMessageReceived) => {
      db.chatMessage.add(assistantMessageReceived).then(clearSending);
      if (sp) sp.end();
      if (tr) tr.end();
    });
  };

  const handleMessageSent = useCallback<ComposeAreaProps["onSend"]>(
    async (newMessage) => {
      let tr = apm.startTransaction("generate", "llm.message");
      let sp = tr?.startSpan("message");
      streamedContentRef.current = "";
      if (chatMessages === undefined) {
        throw new Error("chatMessages is undefined");
      }
      const selectedModel =
        typeof conversationSettings.model !== "string"
          ? conversationSettings.model
          : availableModels.find((llm) =>
              // @ts-ignore
              llm.aliases.includes(conversationSettings.model),
            ) ?? getDefaultModelFromList(availableModels);
      const assistantMessagePromise = !selectedModel.image_model
        ? sendGatewayRequest(
            [...chatMessages, newMessage],
            selectedModel,
            accessToken,
            conversationSettings.rag,
            conversationSettings.pii,
            chatOptions.show_rag,
            handleSnackbarOpen,
          )
        : sendImageGatewayRequest(
            [...chatMessages, newMessage],
            selectedModel,
            accessToken,
            conversationSettings.rag,
            conversationSettings.pii,
            handleSnackbarOpen,
          );
      abortControllerRef.current = assistantMessagePromise.abort;
      const newId = uuid4();
      const assistantMessage = assistantMessagePromise
        .then((response) => {
          if (response.body === null) {
            throw new Error("Couldn't get message from gateway");
          } else if (!response.ok) {
            return response.json();
          }
          const [stream1, stream2] = response.body.tee();
          setStream(stream1);
          return readMessageStream(stream2, updateStreamedMessageText);
        })
        .then((text): IChatMessage => {
          if (typeof text !== "string") {
            return {
              id: newId,
              conversationId: conversationId ?? "",
              role: MessageRole.Assistant,
              content: "Something went wrong: " + text.detail!,
              updated: new Date(),
              model:
                conversationSettings.model ??
                getDefaultModelFromList(availableModels),
              hasFeedback: false,
            };
          }
          return {
            id: newId,
            conversationId: conversationId ?? "",
            role: MessageRole.Assistant,
            content: text,
            updated: new Date(),
            model:
              conversationSettings.model ??
              getDefaultModelFromList(availableModels),
            hasFeedback: false,
          };
        })
        .catch((err) => {
          console.log(err);
          return {
            id: newId,
            conversationId: conversationId ?? "",
            role: MessageRole.Assistant,
            content: err.toString(),
            updated: new Date(),
            model:
              conversationSettings.model ??
              getDefaultModelFromList(availableModels),
            hasFeedback: false,
          };
        });
      setSending();
      await db.chatMessage.add(newMessage);
      assistantMessage.then((assistantMessageReceived) => {
        db.chatMessage.add(assistantMessageReceived).then(clearSending);
        if (sp) sp.end();
        if (tr) tr.end();
      });
    },
    [
      accessToken,
      chatMessages,
      conversationId,
      setSending,
      clearSending,
      conversationSettings,
      availableModels,
      chatOptions.show_rag,
    ],
  );

  const handleConversationSettingsOpen = () => {
    setConversationSettingsOpen(true);
  };

  const handleConversationSettingsClose = () => {
    setConversationSettingsOpen(false);
  };

  const handleConversationSettingsSave = (
    newSettings: IConversationSettings,
  ) => {
    setConversationSettings(newSettings);
  };

  const handleExportTranscript = () => {
    onTranscriptDownloaded(chatMessages ?? []);
  };

  const cancelStream = useCallback(() => {
    setStream(undefined);
    clearSending();
    if (abort != null) {
      try {
        abort();
      } catch (error) {
        console.log("Aborted.");
      }
    }
  }, [clearSending, abort]);

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSnackbarOpen = (notificationText: string) => {
    setSnackbarText(notificationText);
    setSnackbarOpen(true);
  };

  const composeBarHeight = 64;

  async function getNamed(id: string): Promise<boolean | undefined> {
    try {
      const conversation = await db.conversationInfo.get(id);
      return conversation?.named;
    } catch (error) {
      console.error("Failed to fetch named value:", error);
      return undefined;
    }
  }

  const namingInProgress = useRef(false);

  useEffect(() => {
    if (
      conversationId &&
      chatMessages &&
      chatMessages.length > 1 &&
      !namingInProgress.current
    ) {
      (async () => {
        namingInProgress.current = true;
        const isNamed = await getNamed(conversationId);
        if (!isNamed) {
          try {
            const selectedModel =
              typeof conversationSettings.model !== "string"
                ? conversationSettings.model
                : availableModels.find((llm) =>
                    // @ts-ignore
                    llm.aliases.includes(conversationSettings.model),
                  ) ?? getDefaultModelFromList(availableModels);
            const response = await nameConversation(
              chatMessages.slice(1) ?? [],
              selectedModel,
              getDefaultModelFromList(availableModels),
              accessToken,
            );
            if (response.ok) {
              const result = await response.json();
              let updatedName: string =
                result["choices"][0]["message"]["content"];
              if (updatedName.includes(": ")) {
                updatedName = updatedName.split(": ")[1];
              }
              updatedName = updatedName.replaceAll('"', "");
              const now = new Date();
              await db.conversationInfo.put({
                id: conversationId,
                name: updatedName,
                updated: now,
                named: true,
              });
            } else {
              console.error("Failed to automatically name the conversation");
            }
          } catch (error) {
            console.error(
              "Error in automatically naming the conversation:",
              error,
            );
          } finally {
            namingInProgress.current = false;
          }
        } else {
          namingInProgress.current = false;
        }
      })();
    }
  }, [
    chatMessages,
    accessToken,
    availableModels,
    conversationId,
    conversationSettings.model,
  ]);

  if (conversationId == null) {
    return (
      <>
        <Toolbar />
        <Alert
          severity="info"
          sx={{ ml: "auto", mr: "auto", mt: "1em", width: "fit-content" }}
        >
          Select or add a conversation to begin.
        </Alert>
      </>
    );
  }

  return (
    <>
      <Toolbar />
      <Box component="div">
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarText}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          action={
            <Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                sx={{ p: 0.5 }}
                onClick={handleSnackbarClose}
              >
                <Close />
              </IconButton>
            </Fragment>
          }
        />
        <ChatHistory
          chatMessages={chatMessages ?? []}
          stream={stream}
          onRegenerateMessage={handleRegenerateMessage}
          heightOffset={composeBarHeight}
          model={conversationSettings.model}
          defaultSystemPrompt={defaultSystemPrompt}
        />
        <Box
          position="fixed"
          component="div"
          sx={{
            boxSizing: "border-box",
            minHeight: composeBarHeight,
            maxHeight: `calc(${composeBarHeight}px * 3)`,
            bottom: 0,
            right:
              formFactor > FormFactor.Tablet && chatOptions.use_promotions
                ? "280px"
                : 0,
            left: sidebarOpen ? sidebarWidth : 0,
            margin: 0,
            backgroundColor: darkModeEnabled ? "black" : "white",
            overflow: "none",
          }}
        >
          <ComposeArea
            onSend={handleMessageSent}
            enabled={!isSending}
            onConversationSettingsOpen={handleConversationSettingsOpen}
            onExportTranscript={handleExportTranscript}
            onError={handleSnackbarOpen}
            conversationId={conversationId}
            cancelStream={cancelStream}
          />
        </Box>
        <ConversationSettings
          open={conversationSettingsOpen}
          onClose={handleConversationSettingsClose}
          onSave={handleConversationSettingsSave}
          currentRag={conversationSettings.rag}
          currentPii={conversationSettings.pii}
          currentModel={conversationSettings.model}
          currentSystemPrompt={conversationSettings.systemPrompt}
          defaultSystemPrompt={defaultSystemPrompt}
        />
      </Box>
    </>
  );
}
