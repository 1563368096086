import IPromotionsCarousel from "../../types/interfaces/IPromotionsCarousel";
import { useCallback, useEffect, useState } from "react";
import { PromotionSlide } from "./PromotionSlide";
import { IndicatorDot } from "./IndicatorDot";

export const SlideViewer = ({
  promotionsCarousel,
}: {
  promotionsCarousel: IPromotionsCarousel;
}) => {
  const [selectedPromotion, setSelectedPromotion] = useState<number>(0);
  const [timeLeft, setTimeLeft] = useState<number>(
    promotionsCarousel.carousel[0].time_on_ad,
  );

  const handleSetSlide = (index: number) => {
    setSelectedPromotion(index);
  };

  const handleNextSlide = useCallback(() => {
    setSelectedPromotion((prev) =>
      prev === promotionsCarousel.carousel.length - 1 ? 0 : prev + 1,
    );
  }, [promotionsCarousel]);

  const handlePrevSlide = useCallback(() => {
    setSelectedPromotion((prev) =>
      prev === 0 ? promotionsCarousel.carousel.length - 1 : prev - 1,
    );
  }, [promotionsCarousel]);

  useEffect(() => {
    setTimeLeft(promotionsCarousel.carousel[selectedPromotion].time_on_ad);
  }, [selectedPromotion, promotionsCarousel]);

  useEffect(() => {
    if (!timeLeft) {
      handleNextSlide();
      return;
    }
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft, handleNextSlide]);

  return (
    <div className="slide-viewer">
      <PromotionSlide
        promotion={promotionsCarousel.carousel[selectedPromotion]}
        nextSlideCallback={handleNextSlide}
        prevSlideCallback={handlePrevSlide}
      />
      <div className="dot-holder">
        {[...Array(promotionsCarousel.carousel.length).keys()].map((n) => (
          <IndicatorDot
            index={n}
            key={n}
            setSlideCallback={handleSetSlide}
            selectedPromotion={selectedPromotion}
          ></IndicatorDot>
        ))}
      </div>
    </div>
  );
};
