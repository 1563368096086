import IChatMessage from "../../types/interfaces/IChatMessage";
import IModel from "../../types/interfaces/IModel";
import { OidcAccessToken } from "@axa-fr/react-oidc/dist/ReactOidc";

interface FetchPromiseWithAbort extends Promise<Response> {
  abort: AbortController;
}

/**
 * Send an image generation request to LLMGateway
 *
 * @param {IChatMessage[]} chatHistory - Array of chat messages.
 * @param {IModel} model - The model to be used for processing the chat messages.
 * @param {OidcAccessToken} accessToken - Access token for authorization.
 * @param {boolean} [rag=false] - Flag indicating whether to use RAG for response generation.
 * @param {boolean} [pii=false] - Flag indicating whether to filter PII
 * @param {Function} [popupCallback=() => {}] - Callback function for displaying popups.
 *
 * @return {FetchPromiseWithAbort} - A promise that resolves with the API response data.
 */
export default function sendImageGatewayRequest(
  chatHistory: IChatMessage[],
  model: IModel,
  accessToken: OidcAccessToken,
  rag: string[] = [],
  pii: boolean = false,
  popupCallback: (s: string) => void = () => {},
): FetchPromiseWithAbort {
  // Use the extended type here
  const host: string = process.env.REACT_APP_LLMGATEWAY_HOST ?? "";
  const prompt = chatHistory[chatHistory.length - 1];
  const headers: HeadersInit = {
    Authorization: `Bearer ${accessToken.accessToken}`,
    "Content-Type": "application/json",
  };

  // Create an instance of AbortController
  const abortController = new AbortController();
  const { signal } = abortController;

  // Fetch request with signal option
  const fetchPromise = fetch(`${host}/images/generations`, {
    method: "POST",
    body: JSON.stringify({
      model: model.internal_name,
      prompt: prompt.content,
      response_format: "url",
      size: "1024x1024",
    }),
    headers: headers,
    signal, // Pass signal option
  }) as FetchPromiseWithAbort;

  // Attach abort function to the returned promise
  fetchPromise.abort = abortController;

  return fetchPromise;
}
