import { useContext } from "react";
import { PromptTemplatesContext } from "../contexts/PromptTemplatesContext";

export const usePromptTemplates = () => {
  const context = useContext(PromptTemplatesContext);
  if (context === undefined) {
    throw new Error(
      "usePromptTemplates must be used within a PromptTemplatesProvider",
    );
  }
  return context;
};
