export default function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, (text) =>
    text === "or" ||
    text === "and" ||
    text === "of" ||
    text === "in" ||
    text === "on"
      ? text
      : text.charAt(0).toUpperCase() + text.substring(1).toLowerCase(),
  );
}
