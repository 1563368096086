import { ContentChunk } from "../../../types/classes/ContentChunk";
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";

export const ContentChunkDisplay = ({
  chunk,
  id,
  onUpdate,
}: {
  chunk: ContentChunk;
  id: number;
  value: string;
  onUpdate: (chunkId: number, newValue: string) => void;
}) => {
  useEffect(() => {
    onUpdate(id, chunk.content);
  }, [chunk]);
  return <></>;
  /*
  return (
    <Box sx={{ display: "inline-block" }} className="content-chunk">
      <Typography sx={{ lineHeight: "1.5em" }}>{chunk.content}</Typography>
    </Box>
  );
   */
};
