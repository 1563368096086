import { Box, FormControl, TextField } from "@mui/material";
import { ChangeEvent, useCallback, useEffect } from "react";
import { FormFieldChunk } from "../../../types/classes/FormFieldChunk";
import snakeToTitleCase from "../../../functions/formatting/snakeToTitleCase";

export const FormFieldChunkDisplay = ({
  chunk,
  id,
  value,
  onUpdate,
}: {
  chunk: FormFieldChunk;
  id: number;
  value: string;
  onUpdate: (chunkId: number, newValue: string) => void;
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onUpdate(id, event.target.value);
    },
    [chunk, id, value, onUpdate],
  );

  useEffect(() => {
    onUpdate(id, chunk.content);
  }, [chunk]);

  return (
    <Box sx={{ display: "block", width: "100%" }} className="form-field-chunk">
      <FormControl size="small" fullWidth>
        <TextField
          size="small"
          fullWidth
          placeholder={chunk.content}
          onChange={handleChange}
          label={snakeToTitleCase(chunk.name)}
        />
      </FormControl>
    </Box>
  );
};
