import IPromptChunk from "../interfaces/IPromptChunk";

export class ContentChunk implements IPromptChunk {
  readonly type = "content";
  content: string;

  public constructor({ content }: IPromptChunk) {
    if (this.type !== "content") {
      throw new Error(
        "Non content chunk was passed through content constructor. This shouldn't happen.",
      );
    }
    this.content = content;
  }
}
