import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { EditNote, Logout, Settings } from "@mui/icons-material";
import { useOidc, useOidcAccessToken, useOidcUser } from "@axa-fr/react-oidc";
import { useNavigate } from "react-router-dom";
import { withTransaction } from "@elastic/apm-rum-react";
import DarkModeToggle from "./DarkModeToggle";
import { useFormFactor } from "../hooks/useFormFactor";
import { useOidcConfigurationName } from "../hooks/useOidcConfigurationName";
import React, { ReactNode } from "react";
import { useUserInfo } from "../hooks/useUserInfo";
import { useSending } from "../hooks/useSending";
import { FormFactor } from "../types/enums/FormFactor";
import { PromotionSidebar } from "./promotion/PromotionSidebar";
import { useChatOptions } from "../hooks/useChatOptions";

export type ConversationListProps = {
  onConversationAdded: () => void;
  onGlobalSettingsOpened: () => void;
  children: ReactNode;
};

export default withTransaction(
  "ConversationList",
  "component",
)(ConversationList);
function ConversationList({
  onConversationAdded,
  onGlobalSettingsOpened,
  children,
}: ConversationListProps) {
  const { oidcConfigurationName, clearOidcConfigurationName } =
    useOidcConfigurationName();
  const oidc = useOidc(oidcConfigurationName);
  const { oidcUser } = useOidcUser(oidcConfigurationName);
  const oidcAccessToken = useOidcAccessToken(oidcConfigurationName);
  const { userName, roles } = useUserInfo(oidcUser, oidcAccessToken);
  const formFactor = useFormFactor();
  const { isSending } = useSending();
  const navigate = useNavigate();
  const { chatOptions } = useChatOptions();

  const handleLogout = () => {
    oidc.logout(null, { "no_reload:oidc": "true" }).then(() => {
      clearOidcConfigurationName();
      sessionStorage.clear();
      navigate("/login");
    });
  };
  return (
    <>
      <Box sx={{ overflow: "auto" }}>
        <List>
          {formFactor === FormFactor.Mobile ? (
            <>
              <ListItem>
                <Box
                  display="flex"
                  sx={{ padding: "0.25em" }}
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box>
                    <Tooltip title={"Global Settings"}>
                      <IconButton onClick={onGlobalSettingsOpened}>
                        <Settings />
                      </IconButton>
                    </Tooltip>
                    <DarkModeToggle />
                  </Box>
                  <Tooltip
                    title={"Available Roles: " + roles ?? [""].join(", ")}
                  >
                    <Typography>
                      <b>{userName}</b>
                    </Typography>
                  </Tooltip>
                  <Box component="div">
                    <Tooltip title={"Log out of " + userName + "'s account"}>
                      <IconButton onClick={handleLogout}>
                        <Logout />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </ListItem>
              <Divider />
            </>
          ) : (
            <></>
          )}
          {formFactor <= FormFactor.Tablet && chatOptions.use_promotions ? (
            <>
              <PromotionSidebar />
              <Divider />
            </>
          ) : (
            <></>
          )}
          <ListItemButton
            onClick={onConversationAdded}
            disabled={isSending}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <EditNote />
            &nbsp;
            <Typography>New Conversation</Typography>
          </ListItemButton>
          <Divider />
          {children}
        </List>
      </Box>
    </>
  );
}
