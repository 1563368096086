import IModel from "../../types/interfaces/IModel";

/**
 * Sorts two large language models based on their display names in a case-insensitive manner.
 *
 * @param {IModel} llmA - The first large language model to compare.
 * @param {IModel} llmB - The second large language model to compare.
 *
 * @return {-1 | 1 | 0} Returns -1 if llmA should come before llmB, 1 if llmA should come after llmB, or 0 if they are equal.
 */
export default function sortLargeLanguageModels(
  llmA: IModel,
  llmB: IModel,
): -1 | 1 | 0 {
  if (llmA.default && !llmB.default) return -1;
  if (llmB.default && !llmA.default) return 1;
  const nameA = llmA.display_name.toLowerCase();
  const nameB = llmB.display_name.toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
}
