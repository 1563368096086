import IGatewayAPIRequest from "../../types/interfaces/IGatewayAPIRequest";

/**
 * Converts a gateway API request object to the format used by LLMGateway
 *
 * @param {IGatewayAPIRequest} request - The input gateway API request object to be converted.
 *
 * @return {Object} - The converted request object with selected properties.
 */
export default function convertGatewayRequest(request: IGatewayAPIRequest) {
  return {
    messages: request.messages,
    model: request.model,
    rag: request.rag,
    stream: request.stream,
  };
}
