import IChatMessage from "../../types/interfaces/IChatMessage";

/**
 * Cleans the chat messages by only keeping the content and role properties.
 *
 * @param {IChatMessage[]} messages - An array of chat messages to be cleaned.
 * @returns {Partial<IChatMessage>[]} - An array of cleaned chat messages with only content and role properties.
 */
export default function cleanMessages(
  messages: IChatMessage[],
): Partial<IChatMessage>[] {
  return messages.map((m) => {
    return { content: m.content, role: m.role };
  });
}
