import { useContext } from "react";
import { SidebarOpenContext } from "../contexts/SidebarOpenContext";

export const useSidebarOpen = () => {
  const context = useContext(SidebarOpenContext);
  if (context === undefined) {
    throw new Error("useSidebarOpen must be used within a SidebarOpenProvider");
  }
  return context;
};
