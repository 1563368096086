import {
  Alert,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
} from "@mui/material";
import { PropsWithChildren, useEffect, useState } from "react";
import ILoadableContext from "./types/interfaces/ILoadableContext";

export const Loading = ({
  children,
  contexts = [],
}: PropsWithChildren<{ contexts: ILoadableContext[] }>) => {
  const [retryCount, setRetryCount] = useState(0);
  const [retryTrigger, setRetryTrigger] = useState(false);
  const maxRetries = 3;
  const retryInterval = 5000;

  // Filter out contexts that have already loaded successfully
  const state = contexts.filter(
    (s) => s.loading || (s.error !== null && !s.ignoreErrors),
  );

  // Handle auto-retry after a certain interval
  useEffect(() => {
    if (state.length > 0) {
      const timer = setInterval(() => {
        // Check if max retries reached
        if (retryCount < maxRetries) {
          setRetryCount((prev) => prev + 1);
          setRetryTrigger(true);
        } else {
          clearInterval(timer);
        }
      }, retryInterval);
      return () => clearInterval(timer);
    }
  }, [retryCount, state.length]);

  useEffect(() => {
    if (retryTrigger) {
      contexts.forEach((context) => {
        if (context.error) {
          context.reload();
        }
      });
      setRetryTrigger(false);
    }
  }, [retryTrigger, contexts]);

  if (state.length === 0) {
    return <>{children}</>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CssBaseline />
      {state.map((s) => (
        <Alert
          severity={s.error === null ? "info" : "error"}
          key={s.name}
          action={
            <Button onClick={s.reload} color="inherit">
              retry
            </Button>
          }
        >
          {s.error === null
            ? `Loading ${s.name} from LLMGateway` +
              (retryCount > 0 ? `(attempts: ${retryCount + 1})` : "")
            : `Error loading ${s.name} from LLMGateway: ${s.error.message}` +
              (retryCount < maxRetries
                ? ` (attempts: ${retryCount + 1})`
                : " (Max attempts reached. You may still retry manually)")}
        </Alert>
      ))}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          placeItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    </Box>
  );
};
