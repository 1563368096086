import { useState, useEffect } from "react";

function getSessionStorageValue<T>(key: string, defaultValue: T): T {
  const val = sessionStorage.getItem(key);
  return val ? JSON.parse(val) : defaultValue;
}

export default function useSessionStorage<T>(key: string, defaultValue: T) {
  const [value, setValue] = useState(() => {
    return getSessionStorageValue<T>(key, defaultValue);
  });

  useEffect(() => {
    // save the state if the value changes
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [value]);

  useEffect(() => {
    // reload the state if the key changes
    setValue(getSessionStorageValue<T>(key, defaultValue));
  }, [key]);

  return [value, setValue] as const;
}
