import { Alert, Typography } from "@mui/material";

export type ImageProps = {
  src: string;
};

export default function ChatImage(props: any) {
  return (
    <>
      <Alert severity="warning">
        Images generated will expire after 1 hour. Please make sure you download
        any images you want to keep.
      </Alert>
      <img
        alt={props.alt}
        src={props.src}
        style={{ maxWidth: "100%", borderRadius: "15px" }}
      />
      <details>
        <Typography variant="overline" component="summary">
          Processed Image Prompt
        </Typography>
        {props.alt}
      </details>
    </>
  );
}
