import IPromptChunk from "../../types/interfaces/IPromptChunk";

export default function expandContentChunks(
  c: IPromptChunk,
): IPromptChunk | IPromptChunk[] {
  if (c.type === "drop_down" || c.type === "form_field") return c;
  return c.content.split(" ").map((c) => {
    return {
      type: "content",
      content: c,
    };
  });
}
