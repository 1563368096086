import IPromptTemplate from "../../types/interfaces/IPromptTemplate";
import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import IPromptChunk from "../../types/interfaces/IPromptChunk";
import { ContentChunkDisplay } from "./chunks/ContentChunkDisplay";
import { ContentChunk } from "../../types/classes/ContentChunk";
import { DropDownChunkDisplay } from "./chunks/DropDownChunkDisplay";
import { DropDownChunk } from "../../types/classes/DropDownChunk";
import { FormFieldChunkDisplay } from "./chunks/FormFieldChunkDisplay";
import { FormFieldChunk } from "../../types/classes/FormFieldChunk";

export const TemplateDisplay = ({
  template,
  onTextUpdated,
}: {
  template: IPromptTemplate;
  onTextUpdated: (newText: string) => void;
}) => {
  const [chunkList, setChunkList] = useState<
    { chunk: IPromptChunk; value: string; id: number }[]
  >([]);

  const updateText = useCallback(() => {
    onTextUpdated(getTextFromChunks());
  }, [chunkList]);

  const getTextFromChunks = useCallback(() => {
    return chunkList.reduce((prev, c) => prev + " " + c.value, "");
  }, [chunkList]);

  useEffect(() => {
    setChunkList(
      template.content.map((chunk, index) => {
        return { chunk: chunk, value: "", id: index };
      }),
    );
  }, [template]);

  const handleChunkUpdate = useCallback(
    (chunkId: number, newValue: string) => {
      setChunkList((prev) => {
        const selectedChunk = prev.find((c) => c.id === chunkId);
        if (selectedChunk !== undefined) {
          return prev.map((c) =>
            c.id === chunkId ? { ...c, value: newValue } : c,
          );
        }
        return prev;
      });
    },
    [chunkList, template],
  );

  useEffect(() => {
    updateText();
  }, [chunkList]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        columnGap: "0.25em",
        rowGap: "1em",
      }}
    >
      {chunkList.map((c) => {
        switch (c.chunk.type) {
          case "content": {
            return (
              <ContentChunkDisplay
                chunk={c.chunk as ContentChunk}
                id={c.id}
                value={c.value}
                onUpdate={handleChunkUpdate}
                key={c.id}
              />
            );
          }
          case "drop_down": {
            return (
              <DropDownChunkDisplay
                chunk={c.chunk as DropDownChunk}
                id={c.id}
                value={c.value}
                onUpdate={handleChunkUpdate}
                key={c.id}
              />
            );
          }
          case "form_field": {
            return (
              <FormFieldChunkDisplay
                chunk={c.chunk as FormFieldChunk}
                id={c.id}
                value={c.value}
                onUpdate={handleChunkUpdate}
                key={c.id}
              />
            );
          }
        }
      })}
    </Box>
  );
};
