export type IndicatorDotProps = {
  index: number;
  selectedPromotion: number;
  setSlideCallback: (index: number) => void;
};

export const IndicatorDot = ({
  index,
  selectedPromotion,
  setSlideCallback,
}: IndicatorDotProps) => {
  if (index === selectedPromotion) {
    return (
      <span className="indicator-dot" onClick={() => setSlideCallback(index)}>
        ●
      </span>
    );
  }
  return (
    <span className="indicator-dot" onClick={() => setSlideCallback(index)}>
      ○
    </span>
  );
};
