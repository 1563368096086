import { ReactNode, useState } from "react";
import { SidebarOpenContext } from "../../contexts/SidebarOpenContext";
import { useFormFactor } from "../../hooks/useFormFactor";
import { FormFactor } from "../../types/enums/FormFactor";

export const SidebarOpenProvider = ({ children }: { children: ReactNode }) => {
  const formFactor = useFormFactor();
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(
    formFactor !== FormFactor.Mobile,
  );

  const openSidebar = () => {
    setSidebarOpen(true);
  };
  const closeSidebar = () => {
    setSidebarOpen(false);
  };
  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  return (
    <SidebarOpenContext.Provider
      value={{
        sidebarOpen: sidebarOpen,
        sidebarWidth: 290,
        openSidebar: openSidebar,
        closeSidebar: closeSidebar,
        toggleSidebar: toggleSidebar,
      }}
    >
      {children}
    </SidebarOpenContext.Provider>
  );
};
