import IChatMessage from "../../types/interfaces/IChatMessage";

/**
 * Truncates chat messages longer than 300 characters to keep a maximum length of 300 characters by
 * keeping the first 100 characters, adding a truncation indicator, and keeping the last 100 characters.
 *
 * @param {IChatMessage[]} chatMessages - The array of chat messages to be truncated.
 *
 * @return {IChatMessage[]} - Returns the truncated chat messages array with each message limited to
 * 300 or fewer characters.
 */
export default function truncateChatMessages(
  chatMessages: IChatMessage[],
): IChatMessage[] {
  return chatMessages.map((m) => {
    return {
      ...m,
      content:
        m.content.length > 300
          ? `${m.content.slice(0, 100)} [truncated for length...] ${m.content.slice(-100)}`
          : m.content,
    };
  });
}
