import IPromptChunk from "../interfaces/IPromptChunk";

export class FormFieldChunk implements IPromptChunk {
  readonly type = "form_field";
  content: string;
  name: string;

  public constructor({ name, content, type }: IPromptChunk) {
    if (type !== "form_field") {
      throw new Error(
        "Non form_field chunk was passed through form_field constructor. This shouldn't happen.",
      );
    }
    this.content = content;
    this.name = name!;
  }
}
