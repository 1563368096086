import IGatewayOidcConfig from "../../types/interfaces/IGatewayOidcConfig";

/**
 * Retrieves the OIDC configurations from LLMGateway.
 * @return {Promise<Array<IGatewayOidcConfig>>} Array of OIDC configurations available via LLMGateway
 */
export async function getOidcConfigurations(): Promise<
  Array<IGatewayOidcConfig>
> {
  const host: string = process.env.REACT_APP_LLMGATEWAY_HOST ?? "";
  const headers: HeadersInit = {
    "Content-Type": "application/json",
  };
  let resp = await fetch(`${host}/chat-config/oidc`, {
    method: "GET",
    headers: headers,
    signal: AbortSignal.timeout(5000),
  });
  let returnVal = await resp.json();
  const configs = returnVal.configs.filter(
    (c: IGatewayOidcConfig) => typeof c.name === "string",
  );
  if (configs.length === 0) {
    throw new Error(
      "The list of OIDC configurations was empty. Please check that the OIDC configurations are correctly indexed in Elasticsearch.",
    );
  }
  return configs;
}
