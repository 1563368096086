import { ReactNode, useCallback, useEffect, useState } from "react";
import ILargeLanguageModel from "../../types/interfaces/ILargeLanguageModel";
import { AvailableModelsContext } from "../../contexts/AvailableModelsContext";
import { getAllModels } from "../../functions/requests/getAllModels";

export const AvailableModelsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [availableModels, setAvailableModels] = useState<ILargeLanguageModel[]>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const reloadModels = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const availableModelsResponse = await getAllModels();
      setAvailableModels(availableModelsResponse);
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    void reloadModels();
  }, [reloadModels]);

  return (
    <AvailableModelsContext.Provider
      value={{
        availableModels: availableModels,
        loading: loading,
        error: error,
        reload: reloadModels,
        name: "AI models",
      }}
    >
      {children}
    </AvailableModelsContext.Provider>
  );
};
