import { ReactNode, useState } from "react";
import { SendingContext } from "../../contexts/SendingContext";

export const SendingProvider = ({ children }: { children: ReactNode }) => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const setSending = () => {
    setIsSending(true);
  };
  const clearSending = () => {
    setIsSending(false);
  };
  return (
    <SendingContext.Provider
      value={{
        isSending: isSending,
        setSending: setSending,
        clearSending: clearSending,
      }}
    >
      {children}
    </SendingContext.Provider>
  );
};
