import { createContext } from "react";
import ILargeLanguageModel from "../types/interfaces/ILargeLanguageModel";
import ILoadableContext from "../types/interfaces/ILoadableContext";

export interface IAvailableModelsContext extends ILoadableContext {
  availableModels: ILargeLanguageModel[];
}

export const AvailableModelsContext = createContext<IAvailableModelsContext>({
  availableModels: [],
  loading: true,
  error: null,
  reload: () => {},
  name: "AI models",
});
