import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useOidc, useOidcAccessToken, useOidcUser } from "@axa-fr/react-oidc";
import { useNavigate } from "react-router-dom";
import { Info, Logout, Menu, MenuOpen, Settings } from "@mui/icons-material";
import DarkModeToggle from "./DarkModeToggle";
import { useFormFactor } from "../hooks/useFormFactor";
import { useDarkMode } from "../hooks/useDarkMode";
import { useOidcConfigurationName } from "../hooks/useOidcConfigurationName";
import { useUserInfo } from "../hooks/useUserInfo";
import { useSidebarOpen } from "../hooks/useSidebarOpen";
import { FormFactor } from "../types/enums/FormFactor";

export type ToolBarProps = {
  onHelpOpened: () => void;
  onGlobalSettingsOpened: () => void;
};

export default function TopBar({
  onHelpOpened,
  onGlobalSettingsOpened,
}: ToolBarProps) {
  const { oidcConfigurationName, clearOidcConfigurationName } =
    useOidcConfigurationName();
  const { oidcUser } = useOidcUser(oidcConfigurationName);
  const oidcAccessToken = useOidcAccessToken(oidcConfigurationName);
  const { userName, roles } = useUserInfo(oidcUser, oidcAccessToken);
  const { darkModeEnabled } = useDarkMode();
  const { sidebarOpen, openSidebar, closeSidebar } = useSidebarOpen();
  const formFactor = useFormFactor();
  const oidc = useOidc(oidcConfigurationName);
  const navigate = useNavigate();

  const handleLogout = () => {
    oidc.logout(null, { "no_reload:oidc": "true" }).then(() => {
      clearOidcConfigurationName();
      sessionStorage.clear();
      navigate("/login");
    });
  };
  const handleHelp = () => {
    onHelpOpened();
  };

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, maxWidth: "100vw" }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "64px",
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            width: formFactor === FormFactor.Mobile ? "100%" : "auto",
            justifyContent:
              formFactor === FormFactor.Mobile ? "space-between" : "start",
            gap: "2em",
          }}
        >
          {sidebarOpen ? (
            <Tooltip title={"Hide conversation list"}>
              <IconButton onClick={closeSidebar}>
                <MenuOpen />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={"Show conversation list"}>
              <IconButton onClick={openSidebar}>
                <Menu />
              </IconButton>
            </Tooltip>
          )}
          <img
            alt={"logo"}
            src={darkModeEnabled ? "/logo-dark-bar.png" : "/logo-light-bar.png"}
            style={{
              objectFit: "contain",
              width: "auto",
              maxHeight: formFactor === FormFactor.Mobile ? "50%" : "50%",
            }}
          />
          {formFactor === FormFactor.Mobile ? (
            <Tooltip title={"Information"}>
              <IconButton onClick={handleHelp}>
                <Info />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
        </Box>
        {formFactor === FormFactor.Mobile ? (
          <></>
        ) : (
          <Box
            sx={{ width: "max-content", display: "flex", alignItems: "center" }}
          >
            <Tooltip title={"Global Settings"}>
              <IconButton onClick={onGlobalSettingsOpened}>
                <Settings />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Information"}>
              <IconButton onClick={handleHelp}>
                <Info />
              </IconButton>
            </Tooltip>
            <DarkModeToggle />
            <Tooltip title={"Available Roles: " + roles ?? [""].join(", ")}>
              <Typography>
                <b>{userName}</b>
              </Typography>
            </Tooltip>
            <Tooltip title={"Log out of " + userName + "'s account"}>
              <IconButton onClick={handleLogout}>
                <Logout />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}
