/**
 * Initializes the page by adding an error event listener to handle ResizeObserver related errors.
 * If the error message starts with "ResizeObserver", it hides the specific error overlay elements on the page.
 */
export const initializePage = () => {
  window.addEventListener("error", (e) => {
    if (e.message.startsWith("ResizeObserver")) {
      const resizeObserverErrDiv = document.getElementById(
        "webpack-dev-server-client-overlay-div",
      );
      const resizeObserverErr = document.getElementById(
        "webpack-dev-server-client-overlay",
      );
      if (resizeObserverErr) {
        resizeObserverErr.setAttribute("style", "display: none");
      }
      if (resizeObserverErrDiv) {
        resizeObserverErrDiv.setAttribute("style", "display: none");
      }
    }
  });
};
