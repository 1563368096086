import { createContext } from "react";

export const OidcConfigurationNameContext = createContext<{
  oidcConfigurationName: string;
  clearOidcConfigurationName: () => void;
  setOidcConfigurationName: (newName: string) => void;
}>({
  oidcConfigurationName: "default",
  clearOidcConfigurationName: () => {},
  setOidcConfigurationName: (_) => {},
});
