import { ReactNode } from "react";
import { OidcSecureProvider } from "./OidcSecureProvider";
import { AvailableModelsProvider } from "./AvailableModelsProvider";
import { DarkModeEnabledProvider } from "./DarkModeProvider";
import { OidcConfigListProvider } from "./OidcConfigListProvider";
import { OidcConfigurationNameProvider } from "./OidcConfigurationNameProvider";
import { FormFactorProvider } from "./FormFactorProvider";
import { SidebarOpenProvider } from "./SidebarOpenProvider";

/**
 * Wrap children in context providers that are useful to the entire application, even before the user has signed in
 */
export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  return (
    <OidcConfigListProvider>
      <OidcConfigurationNameProvider>
        <OidcSecureProvider>
          <AvailableModelsProvider>
            <FormFactorProvider>
              <SidebarOpenProvider>
                <DarkModeEnabledProvider>{children}</DarkModeEnabledProvider>
              </SidebarOpenProvider>
            </FormFactorProvider>
          </AvailableModelsProvider>
        </OidcSecureProvider>
      </OidcConfigurationNameProvider>
    </OidcConfigListProvider>
  );
};
