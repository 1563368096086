import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import remarkGfm from "remark-gfm";
import CodeBlock from "./chat/CodeBlock";
import ChatImage from "./chat/ChatImage";
import ReactMarkdown from "react-markdown";
import { useChatOptions } from "../hooks/useChatOptions";
import { FormFactor } from "../types/enums/FormFactor";
import { useFormFactor } from "../hooks/useFormFactor";

export type HelpDialogProps = {
  open: boolean;
  onClose: () => void;
};

export default function HelpDialog({ open, onClose }: HelpDialogProps) {
  const { chatOptions } = useChatOptions();
  const formFactor = useFormFactor();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth={true}
      fullScreen={formFactor < FormFactor.Tablet}
    >
      <DialogTitle>Help</DialogTitle>
      <DialogContent>
        <ReactMarkdown
          className="markdown"
          remarkPlugins={[remarkGfm]}
          components={{
            // @ts-ignore
            pre: CodeBlock,
            img: ChatImage,
            a: ({ ...props }) => (
              <a {...props} target="_blank" rel="noopener noreferrer">
                {props.children}
              </a>
            ),
          }}
        >
          {chatOptions.help_text}
        </ReactMarkdown>
        <Button onClick={onClose}>Close</Button>
      </DialogContent>
    </Dialog>
  );
}
