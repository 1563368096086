import { createContext } from "react";
import IChatOptions from "../types/interfaces/IChatOptions";
import ILoadableContext from "../types/interfaces/ILoadableContext";

export interface IChatOptionsContext extends ILoadableContext {
  chatOptions: IChatOptions;
}

export const ChatOptionsContext = createContext<IChatOptionsContext>({
  chatOptions: {
    default_system_prompt: "",
    help_text: "",
    show_rag: false,
    group: [],
  },
  loading: true,
  error: null,
  reload: () => {},
  name: "chat options",
});
