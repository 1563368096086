import { OidcAccessToken } from "@axa-fr/react-oidc/dist/ReactOidc";
import IPromptTemplate from "../../types/interfaces/IPromptTemplate";
import { ContentChunk } from "../../types/classes/ContentChunk";
import { DropDownChunk } from "../../types/classes/DropDownChunk";
import { FormFieldChunk } from "../../types/classes/FormFieldChunk";
import removeTemplateChunkArrays, {
  rawTemplate,
} from "../comparisons-arrays/removeTemplateChunkArrays";
import expandContentChunks from "../comparisons-arrays/expandContentChunks";

/**
 * Retrieves prompt templates from the server based on the provided access token.
 *
 * @param {OidcAccessToken} accessToken - The access token needed to authenticate the request.
 * @return {Promise<Array<IPromptTemplate>>} Array of prompt templates retrieved from the server.
 */
export async function getPromptTemplates(
  accessToken: OidcAccessToken,
): Promise<Array<IPromptTemplate>> {
  const host: string = process.env.REACT_APP_LLMGATEWAY_HOST ?? "";
  const headers: HeadersInit = {
    Authorization: `Bearer ${accessToken.accessToken}`,
    "Content-Type": "application/json",
  };
  let resp = await fetch(`${host}/templates/all`, {
    method: "GET",
    headers: headers,
    signal: AbortSignal.timeout(5000),
  });
  let returnVal = await resp.json();
  let templates = returnVal.templates.map((t: rawTemplate) => {
    return { ...t, content: t.content.map(removeTemplateChunkArrays) };
  }) as IPromptTemplate[];
  templates = templates.map((t) => {
    return {
      name: t.name,
      role: t.role,
      requires_upload: t.requires_upload,
      content: t.content
        .filter((c) => ["content", "drop_down", "form_field"].includes(c.type))
        .flatMap((c) => expandContentChunks(c))
        .map((c) => {
          switch (c.type) {
            case "content": {
              return new ContentChunk(c);
            }
            case "drop_down": {
              return new DropDownChunk(c);
            }
            case "form_field": {
              return new FormFieldChunk(c);
            }
          }
        }),
    };
  });
  return templates;
}
