import { ReactNode, useCallback, useEffect, useState } from "react";
import IPromotionsCarousel from "../../types/interfaces/IPromotionsCarousel";
import { AvailablePromotionsCarouselContext } from "../../contexts/AvailablePromotionsCarouselContext";
import { getPromotionsCarousel } from "../../functions/requests/getPromotionsCarousel";

export const AvailablePromotionsCarouselProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [promotionsCarousel, setPromotionsCarousel] =
    useState<IPromotionsCarousel>({ carousel: [] });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const reloadPromotionsCarousel = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const promotionsCarouselResponse = await getPromotionsCarousel();
      setPromotionsCarousel(promotionsCarouselResponse);
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    void reloadPromotionsCarousel();
  }, [reloadPromotionsCarousel]);

  return (
    <AvailablePromotionsCarouselContext.Provider
      value={{
        promotionsCarousel: promotionsCarousel,
        loading: loading,
        error: error,
        reload: reloadPromotionsCarousel,
        ignoreErrors: true,
        name: "promotions",
      }}
    >
      {children}
    </AvailablePromotionsCarouselContext.Provider>
  );
};
