import IChatMessage from "../../types/interfaces/IChatMessage";
import IModel from "../../types/interfaces/IModel";
import { OidcAccessToken } from "@axa-fr/react-oidc/dist/ReactOidc";
import IGatewayAPIRequest from "../../types/interfaces/IGatewayAPIRequest";
import convertGatewayRequest from "./convertGatewayRequest";
import truncateChatMessages from "../formatting/truncateChatMessages";

/**
 *  Get a generated name based on the chat history from LLMGateway.
 *
 * @param {IChatMessage[]} chatHistory - An array of chat messages used for the conversation.
 * @param {IModel} model - The main model used for generating names.
 * @param {IModel} defaultModel - The fallback model used if the conversation's model is an image model
 * @param {OidcAccessToken} accessToken - The access token for authorization.
 * @return {Promise<Response>} A Promise that resolves with the server response after generating the name
 */
export async function nameConversation(
  chatHistory: IChatMessage[],
  model: IModel,
  defaultModel: IModel,
  accessToken: OidcAccessToken,
): Promise<Response> {
  const host: string = process.env.REACT_APP_LLMGATEWAY_HOST ?? "";
  let textModel = model;
  if (model.image_model) {
    textModel = defaultModel;
  }
  const gateway_api_request: IGatewayAPIRequest = {
    messages: truncateChatMessages(chatHistory),
    model: textModel.internal_name,
    rag: [],
    stream: false,
  };
  const headers: HeadersInit = {
    Authorization: `Bearer ${accessToken.accessToken}`,
    "Content-Type": "application/json",
  };
  return await fetch(`${host}/conversation/generate-name`, {
    method: "POST",
    body: JSON.stringify(convertGatewayRequest(gateway_api_request)),
    headers: headers,
  });
}
