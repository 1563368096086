import IChatMessage from "../../types/interfaces/IChatMessage";
import IModel from "../../types/interfaces/IModel";
import { encodingForModel } from "js-tiktoken";

/**
 * Trims the conversation history to fit within the context window limit.
 *
 * @param {IChatMessage[]} conversationHistory - The array of chat messages representing the conversation history.
 * @param {IModel | null} model - The optional model used for context window limit. Defaults to null if not provided.
 * @param {(s: string) => void} popupCallback - The callback function to handle popup messages.
 *
 * @return {IChatMessage[]} The trimmed conversation history that fits within the context window limit.
 */
export default function trimConversationHistory(
  conversationHistory: IChatMessage[],
  model: IModel | null = null,
  popupCallback: (s: string) => void = () => {},
): IChatMessage[] {
  const tokenLimit = model?.context_window ?? 4_000;
  const encoding = encodingForModel("gpt-4-turbo-preview");
  let encoded = encoding.encode(JSON.stringify(conversationHistory));
  if (encoded.length > tokenLimit * 0.9) {
    popupCallback(
      "This conversation is being trimmed to fit the context window. Consider selecting a different model.",
    );
  }
  while (encoded.length > tokenLimit * 0.9) {
    conversationHistory = conversationHistory.filter((message, index) => {
      return index !== 1;
    });
    encoded = encoding.encode(JSON.stringify(conversationHistory));
  }
  return conversationHistory;
}
