import { useContext } from "react";
import { RAGSourcesContext } from "../contexts/RAGSourcesContext";

export const useRagSources = () => {
  const context = useContext(RAGSourcesContext);
  if (context === undefined) {
    throw new Error("useRagSources must be used within a RagSourcesProvider");
  }
  return context;
};
