import React from "react";
import ReactDOM from "react-dom/client";
import { init as initApm } from "@elastic/apm-rum";
import "./index.css";
import { GlobalProvider } from "./components/providers/GlobalProvider";
import { AppContainer } from "./AppContainer";

const apm = initApm({
  serviceName: "aictrl-chat",
  serverUrl:
    "https://15251626945648249478aa89e79dd009.elastic.expedient.cloud:9243",
  serviceVersion: "1.4.2",
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <GlobalProvider>
      <AppContainer />
    </GlobalProvider>
  </React.StrictMode>,
);
