import IPromptChunk from "../interfaces/IPromptChunk";

export class DropDownChunk implements IPromptChunk {
  readonly type = "drop_down";
  content: string;
  name: string;
  options: string[];

  public constructor({ name, content, type }: IPromptChunk) {
    if (type !== "drop_down") {
      throw new Error(
        "Non drop_down chunk was passed through drop_down constructor. This shouldn't happen.",
      );
    }
    this.content = content;
    this.name = name!;
    this.options = content.split(",");
  }
}
