export type rawTemplateChunk = {
  type: "form_field" | "drop_down" | "content" | string[];
  content: string | string[];
  name?: string | string[] | undefined;
};

export type rawTemplate = {
  name: string;
  role: string | string[];
  requires_upload: boolean;
  content: rawTemplateChunk[];
};

export default function removeTemplateChunkArrays(chunk: rawTemplateChunk) {
  let cleaned = {
    type: Array.isArray(chunk.type) ? chunk.type[0] : chunk.type,
    content: Array.isArray(chunk.content) ? chunk.content[0] : chunk.content,
  };
  if (chunk?.name === undefined) {
    return cleaned;
  }
  return {
    ...cleaned,
    name: Array.isArray(chunk.name) ? chunk.name[0] : chunk.name,
  };
}
