import { ReactNode } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import { OidcConfigurationNameContext } from "../../contexts/OidcConfigurationNameContext";

export const OidcConfigurationNameProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [oidcConfigurationName, setOidcConfigurationName] =
    useLocalStorage<string>("configurationName", "default");
  const clearAuthProvider = () => {
    setOidcConfigurationName("default");
  };

  return (
    <OidcConfigurationNameContext.Provider
      value={{
        oidcConfigurationName: oidcConfigurationName,
        clearOidcConfigurationName: clearAuthProvider,
        setOidcConfigurationName: setOidcConfigurationName,
      }}
    >
      {children}
    </OidcConfigurationNameContext.Provider>
  );
};
