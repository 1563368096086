import { OidcAccessToken } from "@axa-fr/react-oidc/dist/ReactOidc";
import IChatOptions from "../../types/interfaces/IChatOptions";

/**
 * Retrieves chat options for a user using the provided access token.
 *
 * @param {OidcAccessToken} accessToken - The access token required to authenticate the user.
 * @return {Promise<IChatOptions>} - A promise that resolves to the chat options object retrieved.
 */
export async function getChatOptions(
  accessToken: OidcAccessToken,
): Promise<IChatOptions> {
  const host: string = process.env.REACT_APP_LLMGATEWAY_HOST ?? "";
  const headers: HeadersInit = {
    Authorization: `Bearer ${accessToken.accessToken}`,
    "Content-Type": "application/json",
  };

  let resp = await fetch(`${host}/chat-config/options`, {
    method: "GET",
    headers: headers,
  });
  return await resp.json();
}
