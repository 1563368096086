import { useContext } from "react";
import { FormFactorContext } from "../contexts/FormFactorContext";

export const useFormFactor = () => {
  const context = useContext(FormFactorContext);
  if (context === undefined) {
    throw new Error("useFormFactor must be used within a FormFactorProvider");
  }
  return context;
};
