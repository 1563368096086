import { AvailableModelsContext } from "../contexts/AvailableModelsContext";
import { useContext } from "react";

export const useAvailableModels = () => {
  const context = useContext(AvailableModelsContext);
  if (context === undefined) {
    throw new Error(
      "useAvailableModels must be used within an AvailableModelsProvider",
    );
  }
  return context;
};
