import { createContext } from "react";
import ILoadableContext from "../types/interfaces/ILoadableContext";
import IPromotionsCarousel from "../types/interfaces/IPromotionsCarousel";

export interface IAvailablePromotionsCarouselContext extends ILoadableContext {
  promotionsCarousel: IPromotionsCarousel;
}

export const AvailablePromotionsCarouselContext =
  createContext<IAvailablePromotionsCarouselContext>({
    promotionsCarousel: { carousel: [] },
    loading: true,
    error: null,
    reload: () => {},
    name: "promotions",
  });
