import { useEffect, useState } from "react";
import readMessageStream from "../../functions/requests/readMessageStream";
import IChatMessage from "../../types/interfaces/IChatMessage";
import { MessageRole } from "../../types/enums/MessageRole";
import ChatMessage from "./ChatMessage";
import { Box } from "@mui/material";
import IModel from "../../types/interfaces/IModel";

export type IncomingMessageStream = ReadableStream<Uint8Array>;

export type IncomingMessageProps = {
  stream?: IncomingMessageStream;
  scrollCallback: () => void;
  model: IModel | null;
};

export default function IncomingMessage({
  stream,
  scrollCallback,
  model,
}: IncomingMessageProps) {
  const [content, setContent] = useState("");

  useEffect(() => {
    if (stream === undefined) return;
    if (stream.locked) return;
    readMessageStream(stream, (token) => {
      setContent((prev) => prev + token);
      scrollCallback();
    })
      .then()
      .catch((_) => {});
  }, [stream]);

  const chatMessage: IChatMessage = {
    id: "",
    conversationId: "",
    role: MessageRole.Assistant,
    content: content,
    updated: new Date(),
    model: model,
    hasFeedback: false,
  };
  return (
    <Box sx={{ width: "100%" }}>
      <ChatMessage fromUser={false} message={chatMessage} />
    </Box>
  );
}
