import { createContext } from "react";
import IOidcConfig from "../types/interfaces/IOidcConfig";
import ILoadableContext from "../types/interfaces/ILoadableContext";

export interface IOidcConfigListContext extends ILoadableContext {
  oidcConfigList: IOidcConfig[];
}

export const OidcConfigListContext = createContext<IOidcConfigListContext>({
  oidcConfigList: [],
  loading: true,
  error: null,
  reload: () => {},
  name: "OIDC configurations",
});
