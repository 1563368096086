import { Fragment, ReactNode, useContext } from "react";
import { Loading } from "../../Loading";
import { OidcProvider } from "@axa-fr/react-oidc";
import { OidcConfigurationNameContext } from "../../contexts/OidcConfigurationNameContext";
import { useOidcConfigList } from "../../hooks/useOidcConfigList";

/**
 * Wrap the child components in an OidcProvider, but only if a configuration has been selected and has been loaded in
 * from LLMGateway
 */
export const OidcSecureProvider = ({ children }: { children: ReactNode }) => {
  const { oidcConfigList } = useOidcConfigList();
  const { oidcConfigurationName, setOidcConfigurationName } = useContext(
    OidcConfigurationNameContext,
  );
  let configuration = oidcConfigList.find(
    (c) => c.name === oidcConfigurationName,
  );
  if (oidcConfigList.length == 1) {
    setOidcConfigurationName(oidcConfigList[0].name);
    configuration = oidcConfigList[0];
  }
  if (configuration != null) {
    return (
      <OidcProvider
        configuration={configuration}
        configurationName={oidcConfigurationName}
        loadingComponent={Loading}
        authenticatingComponent={Loading}
        callbackSuccessComponent={Loading}
        sessionLostComponent={Loading}
      >
        {children}
      </OidcProvider>
    );
  } else {
    return <Fragment>{children}</Fragment>;
  }
};
