import { OidcAccessToken } from "@axa-fr/react-oidc/dist/ReactOidc";
import { OidcUserInfo } from "@axa-fr/react-oidc";

export const useUserInfo = (
  oidcUserInfo: OidcUserInfo,
  oidcAccessToken: OidcAccessToken,
): {
  userName: string | undefined;
  roles: string[] | undefined;
} => {
  const userName =
    oidcUserInfo?.name ??
    (oidcAccessToken.accessTokenPayload?.name as string | undefined);
  const roles =
    oidcUserInfo?.groups ??
    (oidcAccessToken.accessTokenPayload?.groups as string[] | undefined);
  return { userName: userName, roles: roles };
};
